<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <van-cell class="cell-expressway" @click="expressWaysShow = true" is-link>
      <template #title>
        <span style="color: red;">{{ $t('运输方式') }}</span>
      </template>
      <template>
        <span style="color: red;">{{ selectExpressWay.value ? selectExpressWay.text : $t('请选择运输方式') }}</span>
      </template>
    </van-cell>

    <van-empty v-if="!orderRes || !orderRes.containerCargoLists || !orderRes.containerCargoLists.length" image="search"
      :description="$t('暂无数据')" />

    <div v-if="orderRes && orderRes.containerCargoLists && orderRes.containerCargoLists.length" style="margin-top: 45px;">
      <div v-for="container in orderRes.containerCargoLists" :key="container.id">
        <div class="cargo-item" v-for="item in container.cargoList" :key="item.id">
          <div class="cargo-item__header" @click="checkCargo(item)">
            <span class="cargo-item__header-no">{{ $t("单号") }}：{{ item.trackingNo }}</span>
            <van-image width="22" height="22" :src="require(item.checked
              ? '@/assets/icons/tracking_select.png'
              : '@/assets/icons/tracking_unselect.png')
              " />
          </div>
          <div :data-id="item.id">
            <van-cell :title="$t('重量')" :value="$t('weight 公斤', { weight: item.weight })" />
            <van-cell :title="$t('长宽高(单位公分)')" :value="item.length + ' × ' + item.width + ' × ' + item.height" />
            <van-cell :title="$t('体积')" :value="(item.length * item.width * item.height) / 1000000 + ' m³'
              " />
            <div v-if="item.discount > 0 && item.discount < 1">
              <van-cell :title="$t('折扣')" :value="item.discount * 10 + $t('折')" />
              <van-cell :title="$t('转运费')">
                <div style="display: flex; justify-content: flex-end">
                  <div style="text-decoration: line-through; margin-right: 10px">
                    {{ item.totalAmont }}{{ " " + $t("铢") }}
                  </div>
                  <div>
                    {{ item.totalDiscountedAmont }}{{ " " + $t("铢") }}
                  </div>
                </div>
              </van-cell>
            </div>
            <van-cell v-else :title="$t('转运费') +
              '（' +
              $t(convertBillingMethod(item.billingMethod)) +
              '）'
              " :value="item.totalAmont + ' ' + $t('铢')" />
            <!-- <van-cell title="当前汇率" value="1 人民币 = {{ item.exchangeRate }} 泰铢"/>
          <van-cell title="转运费(人民币)" value="{{ item.totalDiscountedRmbAmount }} 元"/> -->
            <van-cell v-if="item.fServiceAmount" :value="item.fServiceAmount + ' ' + $t('铢')" :title="$t('增值服务')" is-link
              :to="{
                name: 'followup',
                query: {
                  cargoIds: item.id,
                },
              }" />
            <van-cell :title="$t('备注')" :value="item.remark ? item.remark : ''" />
          </div>
        </div>
      </div>
    </div>

    <div class="pay-summary" v-if="orderRes && orderRes.containerCargoLists && orderRes.containerCargoLists.length">
      <div style="background-color: #fff; margin-bottom: 10px" class="contact">
        <van-cell :title="$t('收件地址')" :to="{
          name: 'contactList',
          query: {
            scene: 'pick',
          },
        }" is-link>
          <div v-if="contact">
            <div class="address">
              {{ contact.address }}
            </div>
            <span>{{ contact.name }} / {{ contact.mobile }}</span>
          </div>
          <div v-else>{{ $t("请选择收货地址") }}</div>
        </van-cell>
      </div>

      <van-cell v-if="!orderRes.minimumOrderAmount || orderRes.minimumOrderAmount <= orderRes.totalForeignFee"
        :title="$t('转运费')" :value="orderRes.totalForeignFee + ' ' + $t('铢')" />
      <van-cell v-else :title="$t('转运费')"
        :label="orderRes.minimumOrderAmount ? $t('最低0.2立方米计费') + orderRes.minimumOrderAmount + ' ' + $t('铢') : ''">
        <div style="display:flex; justify-content: flex-end">
          <div style="text-decoration:line-through; margin-right: 10px">{{ orderRes.totalForeignFee }} {{ $t("铢") }}</div>
          <div> {{ orderRes.minimumOrderAmount }} {{ $t("铢") }} </div>
        </div>
      </van-cell>

      <van-cell v-if="orderRes.secondLegFee" :value="orderRes.secondLegFee + ' ' + $t('铢')" :title="$t('尾程运费')" />

      <van-cell v-if="orderRes.totalServiceFee" :value="orderRes.totalServiceFee + ' ' + $t('铢')" :title="$t('增值服务')"
        is-link @click="gotoFollowup" />

      <van-cell v-if="orderRes.preferentialFee" :title="$t('折扣立减')"
        :value="'-' + orderRes.preferentialFee + ' ' + $t('铢')" />

      <van-cell v-if="avaiableCouponList && avaiableCouponList.length" :title="$t('优惠券')" :value="orderRes.couponFee && selectedCoupon
        ? selectedCoupon.title + '，-' + orderRes.couponFee + ' ' + $t('铢')
        : $t('请选择优惠券')
        " is-link @click="toggleCouponList" />
      <van-cell v-else :title="$t('优惠券')" :value="$t('无可用优惠券')" is-link @click="toggleCouponList" />

      <van-cell :title="$t('实付')" :value="(orderRes.totalDiscountedForeignFee
        ? orderRes.totalDiscountedForeignFee + ' ' + $t('铢') : '')" />
    </div>

    <div class="placeholder-div"></div>

    <div v-if="orderRes && orderRes.totalDiscountedForeignFee">
      <div class="order-pay-tip">
        <!-- <div v-if="payByBalance" class="pay-tip">
          {{ $t('支付有余额', {
            totalDiscountedForeignFee: orderRes.totalDiscountedForeignFee,
            balance: orderRes.cashPayAmount <= 0 ? orderRes.totalDiscountedForeignFee : orderRes.balance, cashPayAmount:
              orderRes.cashPayAmount > 0 ? '，' + $t('扫码支付') + orderRes.cashPayAmount + $t('铢') : ''
          }) }}
        </div>
        <div v-else class="pay-tip">
          {{ $t('支付无余额', {
            totalDiscountedForeignFee: orderRes.totalDiscountedForeignFee,
          }) }}
        </div> -->
        <div class="pay-tip">
          {{ $t('留仓集包提示') }}
        </div>
      </div>

      <van-submit-bar :button-text="$t('支付')" @submit="doSubmit" :loading="submitLoading" :disabled="submitLoading">
      </van-submit-bar>
    </div>

    <van-popup v-model="showCouponList" position="bottom" close-on-click-overlay>
      <van-picker show-toolbar :title="$t('优惠券')" :confirm-button-text="$t('确认')" :cancel-button-text="$t('取消')"
        :default-index="avaiableCouponList && avaiableCouponList.length ? 1 : 0" :columns="couponList"
        @cancel="toggleCouponList" @confirm="onCouponSelected" />
    </van-popup>

    <van-popup v-model="expressWaysShow" round position="bottom">
      <van-picker show-toolbar :columns="expressWays" @cancel="expressWaysShow = false" @confirm="onConfirm"
        :confirm-button-text="$t('确认')" :cancel-button-text="$t('取消')" />
    </van-popup>

    <van-popup v-model="showPaymentChoice" closeable position="bottom" custom-style="padding: 30px">
      <van-grid clickable column-num="1">
        <van-grid-item use-slot>
          <div @click="doSubmit" class="payment-item">
            <!-- <van-radio name="1" checked-color="red" value="1"> -->
            <van-image width="60" height="60" :src="require('@/assets/icons/promptpay.png')" />
            <div style="margin-top: 10px">PromptPay</div>
          </div>
        </van-grid-item>
        <van-grid-item use-slot v-if="false">
          <div @click="gotoTopup" class="payment-item">
            <!-- <van-radio name="2" checked-color="red"> -->
            <van-icon name="balance-list" size="60px" color="#ecba8d" />
            <div style="margin-top: 10px">{{ $t('余额支付') }}</div>
            <!-- </van-radio> -->
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>

    <van-popup v-model="payCodeShow" class="pay-code" @closed="() => {
      payData = {}
    }">
      <div class="pay-code-content">
        <div class="pay-code-header">
          <van-icon name="cross" color="white" style="line-height: 30px;padding: 0px 10px;"
            @click="payCodeShow = false" />
        </div>
        <div class="top-tip">
          {{ $t('Please use PromptPay to scan this QRCode') }}
        </div>
        <div style="text-align: center;">
          <van-image width="200" height="200" :src="payData ? payData.imgdat : ''" />
        </div>
        <div class="middle-tip">{{ $t('DO NOT pay more than once!') }}</div>
        <div class="bottom-tip">
          <div>{{ $t('1. Screenshot this OR Code.') }}</div>
          <div>{{ $t('2. Open mobile banking app on your pohone.') }}</div>
          <div>{{ $t("3. Select 'Scan' or 'QR code', then choose 'Open Picture'") }}</div>
          <div>{{ $t('4. Select the QR Code you have received and pay') }}</div>
        </div>
        <div style="text-align: center;padding-bottom: 10px;">
          <van-button style="border-radius: 5px;" color="#013967" :loading="payLoading" @click="handlePayResult">{{
            $t('完成支付')
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { convertBillingMethod } from "@/utils/dict";

import {
  Tag,
  Cell,
  CellGroup,
  Picker,
  Popup,
  Toast,
  Collapse,
  CollapseItem,
  SubmitBar,
  Icon,
  Checkbox,
  Image as VanImage,
  Dialog,
  Grid, GridItem,
  Empty,
  Button
} from "vant";

import { getDefaultContact, preorder, order, pay, getPickupPointCount, transactions } from "@/service/api";
import { EventBus } from "@/utils/event-bus.js";

export default {
  components: {
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [SubmitBar.name]: SubmitBar,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [VanImage.name]: VanImage,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Empty.name]: Empty,
    [Button.name]: Button,
  },
  data() {
    return {
      orderRes: {
        containerCargoLists: [],
      },
      payByBalance: false,//有余额并且余额不够则设置为true，在下单时候提交
      activeNames: [],
      showCouponList: false,
      couponList: [],
      selectedCoupon: {},
      avaiableCouponList: [],
      submitLoading: false,
      contact: null,
      checked: false,
      selectExpressWay: { text: this.$t('陆运'), value: 1 },
      expressWaysShow: false,
      expressWays: [
        { text: this.$t('陆运'), value: 1, },
        { text: this.$t('海运'), value: 2, }],
      couponId: undefined,
      showPaymentChoice: false,
      isNeedReset: true,
      payCodeShow: false,
      payData: undefined,
      payLoading: false,
      orderNo: undefined
    };
  },
  mounted() {
    EventBus.$on("contact-select", (msg) => {
      const contact = JSON.parse(msg);
      this.contact = contact;
    });

    EventBus.$on("order-reset", () => {
      this.isNeedReset = true
      this.doReset();
    });
  },
  activated() {
    const { v3 } = this.$route.query;
    this.isV3 = v3 || "true" == v3 ? true : false;

    if (this.isNeedReset) {
      this.selectExpressWay = { text: this.$t('陆运'), value: 1 }
      this.doReset();
      this.getDefaultContact();
    }

    this.isNeedReset = false
    this.preorder(this.preOrderParams());
  },
  methods: {
    doReset() {
      this.orderRes = {};
      this.activeNames = [];
      this.contact = null;
      this.couponId = undefined;
      this.containerCargoLists = [];
      this.orderNo = undefined
    },
    //查询收件地址附近的自提点数量
    async queryPickupPointCount(postalCode) {
      const pickupRes = await getPickupPointCount({ postalCode })
      if (pickupRes.success) {
        this.pickupPointCount = pickupRes.data
        this.pickupPoint = {}
      }
    },
    //查询支付结果
    async handlePayResult() {
      const payload = { currentPage: this.page, pageSize: 15, orderNo: this.orderNo };

      this.payLoading = true
      const res = await transactions(payload);
      this.payLoading = false
      const { success, data } = res
      if (success) {
        if (data && data.length >= 1) {
          const status = data[0].status
          if (status == 6) {
            this.$router.replace({
              name: "orderSuccess",
              query: { orderNo: payload.orderNo },
            });
          } else
            Toast.fail(this.$t('支付未成功，请确认已付款'))
        } else
          Toast.fail(this.$t('支付未成功，请确认已付款'))
      }
    },
    convertBillingMethod(value) {
      return convertBillingMethod(value);
    },
    toggleCouponList() {
      this.showCouponList = !this.showCouponList;
    },
    onCouponSelected(value, index) {
      this.couponId = value.couponId;

      this.preorder(this.preOrderParams());
      this.toggleCouponList();
    },
    onConfirm(expressWayValue) {
      this.containerCargoLists = [];
      this.expressWaysShow = false;
      this.selectExpressWay = expressWayValue;
      this.preorder(this.preOrderParams());
    },
    gotoFollowup() {
      const { containerCargoLists } = this.orderRes;
      const cargoIdAry = [];
      containerCargoLists.forEach((container) => {
        container.cargoList.forEach((cargo) => {
          if (cargo.checked && cargo.fServiceAmount) cargoIdAry.push(cargo.id);
        });
      });
      const cargoIds = cargoIdAry.join(",");
      this.$router.push({
        name: "followup",
        query: { cargoIds: cargoIds },
      });
    },
    checkCargo(item) {
      if (item.checked) {
        let count = 0;
        this.containerCargoLists.forEach((container) => {
          container.cargoList.forEach((cargo) => {
            if (cargo.checked) count++;
          });
        });

        if (count > 1) {
          item.checked = !item.checked;
          this.preorder(this.preOrderParams());
        }
      } else {
        item.checked = !item.checked;
        this.preorder(this.preOrderParams());
      }
    },
    async getDefaultContact() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getDefaultContact();
      if (res.success) {
        this.contact = res.data;
        //查询此地址附近是否有自提点      
        this.queryPickupPointCount(res.data.postalCode);
      } else Toast.fail(res.message);

      Toast.clear();
    },
    preOrderParams() {
      let params = { isV3: this.isV3, expressWay: this.selectExpressWay.value, payByBalance: this.payByBalance };

      let cargoIds = [];
      if (!this.containerCargoLists) return params;
      this.containerCargoLists.forEach((container) => {
        container.cargoList.forEach((cargo) => {
          if (cargo.checked) cargoIds.push(cargo.id);
        });
      });

      const cids = cargoIds.join(",");
      params = { ...params, cargoIds: cids };

      if (this.couponId != undefined) {
        params = { ...params, couponId: this.couponId };
      }

      const containerNoAry = this.containerCargoLists.map(
        (item) => item.containerNo
      );
      const containers = containerNoAry.join(",");
      let attach = { containerNoStr: containers };

      if (this.contact) {
        params = { ...params, contactId: this.contact.id };
        attach = { ...attach, contactId: this.contact.id };
      }
      attach = {
        ...attach,
        fExpressCompany: "",
      };

      params = { ...params, attach };

      return params;
    },
    async doSubmit() {
      if (!this.contact) {
        Toast.fail(this.$t("请选择收货地址"));
        return;
      }

      const { cashPayAmount } = this.orderRes
      if (!this.showPaymentChoice && cashPayAmount > 0) {
        //如果余额不够，则弹框让用户选择支付方式
        this.showPaymentChoice = true
        return;
      }
      this.showPaymentChoice = false

      const ksherPrompt = 12; //ksher prompot支付
      const balancePay = 3; //全部使用余额支付
      let payChannel = 0;
      if (cashPayAmount <= 0) {
        //如果现金支付金额 <=0，表示余额足够支付，则全部用余额支付
        payChannel = balancePay;
        this.payByBalance = false;//全部是余额支付，此参数设置为false
      } else {
        //余额不够或者无余额，则需要用微信支付支付一部分
        payChannel = ksherPrompt;
      }

      const payload = this.preOrderParams();
      this.submitLoading = true;
      const res = await order(payload);
      if (res.success) {
        this.orderNo = res.data.orderNo
        const params = {
          orderNo: res.data.orderNo,
          payChannel: payChannel,
          payByBalance: this.payByBalance,
          orderType: 1
        };
        this.pay(params);
      } else {
        //有工单待处理，进行单独提示
        if (res.code == 80038) {
          Dialog.alert({
            message: this.$t("正在计算增值服务费用")
          }).then(() => {
          });
        } else {
          Toast(res.message);
        }
      }
      this.submitLoading = false;
    },
    async pay(payload) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const payRes = await pay(payload);
      if (payRes.success) {

        const { PaymentID } = payRes.data
        if (PaymentID) {
          this.payCodeShow = true
          this.payData = payRes.data
        } else
          this.$router.replace({
            name: "orderSuccess",
            query: { orderNo: payload.orderNo },
          });
        Toast.clear();
      } else {
        if (payRes.code === 40810) Toast.fail(this.$t("余额不足"));
        else Toast.fail(payRes.message);
      }
    },
    async preorder(payload) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const params = { ...payload, isV3: this.isV3 };
      const res = await preorder(params);
      if (res && res.success) {
        const avaiableCouponList = res.data.couponList
          .filter((c) => !c.disabled)
          .map((item) => ({
            text:
              item.value +
              this.$t("铢") +
              item.title +
              "-" +
              this.$t("剩余") +
              item.totalCount +
              this.$t("张"),
            couponId: item.id,
            disabled: item.disabled,
          }));
        const disabledCouponList = res.data.couponList
          .filter((c) => c.disabled)
          .map((item) => ({
            text:
              item.value +
              this.$t("铢") +
              item.title +
              "-" +
              this.$t("满可用", { amount: item.minimumAmount }),
            couponId: item.id,
            disabled: item.disabled,
          }));
        const couponList = [...avaiableCouponList, ...disabledCouponList];

        if (avaiableCouponList && avaiableCouponList.length) {
          const defaultSelectedCoupon = {
            text: this.$t("取消用券"),
            couponId: 0,
          };
          couponList.unshift(defaultSelectedCoupon);
        }

        let selectedCoupon = {};
        if (res.data.couponId) {
          selectedCoupon = res.data.couponList.find(
            (c) => c.id == res.data.couponId
          );
        }
        let activeNames = [];
        for (let i = 0; i < res.data.containerCargoLists.length; i++) {
          activeNames.push(i + 1);
        }

        let { containerCargoLists } = res.data;
        let selectCargos = [];
        if (this.containerCargoLists && this.containerCargoLists.length !== 0) {
          this.containerCargoLists.forEach((container) => {
            container.cargoList.forEach((cargo) => {
              if (cargo.checked) selectCargos = [...selectCargos, cargo.id];
            });
          });

          containerCargoLists.forEach((container) => {
            container.cargoList.forEach((cargo) => {
              const { id } = cargo;
              const index = selectCargos.indexOf(id);
              if (index >= 0) cargo.checked = true;
            });
          });
        } else {
          containerCargoLists.forEach((container) => {
            container.cargoList.forEach((cargo) => {
              cargo.checked = true;
            });
          });
        }

        const { couponId } = res.data;

        this.couponId = couponId;
        this.containerCargoLists = containerCargoLists;
        this.couponList = couponList;
        this.avaiableCouponList = avaiableCouponList;
        this.selectedCoupon = selectedCoupon;
        this.activeNames = activeNames;
        this.payByBalance = res.data.balance ? true : false
      }
      this.orderRes = res.data;
      Toast.clear();
    },
    gotoTopup() {
      const { balance, totalDiscountedForeignFee } = this.orderRes;
      this.showPaymentChoice = false
      Dialog.confirm({
        title: this.$t('充值提示'),
        confirmButtonText: this.$t('前往充值'),
        message: this.$t('前往充值提示', { balance: balance ? balance : 0, totalDiscountedForeignFee }),
      }).then(() => {
        this.$router.push({
          name: "recharge"
        });
      }).catch(() => {
      });
    },
  },
};
</script>
<style lang="less" scoped>
/* pages/order/index.wxss */
.cargo-item {
  border: 1px solid #f3f3f3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.cargo-item__header {
  background-color: #f8f9fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #000;
  font-size: 14px;
  padding: 10px;
  display: flex;
}

.cargo-item__header-no {
  font-size: 16px;
  flex: 1;
}

.pay-summary {
  margin-top: 10px;
}

.amount {
  font-size: 16px !important;
  font-weight: bold;
  color: red;
}

.placeholder-div {
  width: 100%;
  height: 120px;
}

.pay-tip {
  font-size: 14px;
  padding-left: 5px;
}

.order-pay-tip {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 50px;
  background-color: #fff7cc;
  color: #f56723;
  padding: 10px;
}

.contact {
  .van-cell {
    align-items: center;
  }

  .van-cell__title {
    flex: none;
  }
}

.payment-item {
  text-align: center;
}

.pay-code {
  background-color: transparent !important;
}

.pay-code-content {
  background-color: white;
  border-radius: 5px;
  width: 280px;
  min-height: 200px;
}

.pay-code-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #013967;
  height: 30px;
  text-align: right;
}

.top-tip {
  font-size: 0.9rem;
  color: #013967;
  padding: 20px;
  text-align: center;
}


.middle-tip {
  color: #ff693a;
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
}

.bottom-tip {
  padding: 20px;
  font-size: 0.9rem;
}

.cell-expressway {
  position: fixed;
  z-index: 2003;
  top: 0px;
  background-color: white;
}
</style>